import { gql, useQuery } from "@apollo/client";
import React from "react";
import Link from "gatsby-link";
import CoverPlaceholder from "../images/vinyl-record-svgrepo-com.svg";

const thisQuery = gql`
  query GetCategories($letter: String, $pagetitle: String) {
    categories(
      sort: "menutitle"
      where: { letterFilter_contains: $letter, pagetitle_contains: $pagetitle }
      limit: 299
    ) {
      id
      pagetitle
      menutitle
      covers {
        url
      }
    }
  }
`;

function CatCovers({ letter, search }) {
  const { loading, error, data } = useQuery(thisQuery, {
    variables: {
      letter: letter?.toLowerCase(),
      pagetitle: search === "" ? "" : search,
    },
  });

  if (loading)
    return (
      <div>
        <div className="align-middle w-full text-center filter drop-shadow-lg">
          <img
            className="h-32 m-32 animate-spin m-auto"
            src={CoverPlaceholder}
            alt="Loading..."
          />
          <h3 className="text-lg leading-6 font-medium space-y1 mt-8 text-primary-dark animate-pulse font-bold">
            Serial Records loading...
          </h3>
        </div>
      </div>
    );
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      {data !== undefined && (
        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8">
          {data.categories.map((category) => (
            <li key={category.id}>
              <div className="space-y-4">
                <Link to={`/category?id=${category.id}`}>
                  <div className="aspect-w-1 aspect-h-1">
                    {category.covers[0] !== undefined && (
                      // process.env.GATSBY_STAPI_GRAPHQL +
                      <img
                        id={category.id}
                        className="object-scale-down cursor-pointer shadow-lg rounded-lg"
                        src={category.covers[0].url}
                        alt=""
                      />
                    )}
                  </div>
                </Link>

                <div className="space-y-2">
                  <div className="text-lg leading-6 font-medium space-y1">
                    {category.pagetitle != null && (
                      <h3 className="text-primary-dark font-bold">
                        {category.pagetitle}
                      </h3>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CatCovers;
